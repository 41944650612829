import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useTypography } from "../styles/Typography"
import { NEWS_PATH } from "../utils/constants"
import { format } from "date-fns"
import clsx from "clsx"
import ButtonLink from "./ButtonLink"
import { Colors } from "../utils/colors"
import { Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        maxWidth: 1080,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: 24,
        justifyContent: "space-between",
        backgroundColor: Colors.white,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)",
        borderRadius: 4,
        marginBottom: 32,
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    tab: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 705
    },
    img: {
        minWidth: 340,
        width: 340,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
            marginRight: 16,
            height: 240
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: 16
        }
    },
    text: {
        marginBottom: 16
    }
}))

interface IProps {
    date: string
    img: string
    title: string
    body: string
    url?: string
    pdf?: string
}

const NewsItem: FC<IProps> = ({ date, img, title, body, url, pdf }) => {
    const classes = useStyles()
    const typo = useTypography()

    return (
        <article className={classes.card}>
            <div className={classes.img}>
                <img src={`${NEWS_PATH}${img}`} alt="News Article" loading="lazy" />
            </div>
            <div className={classes.tab}>
                <p className={clsx(typo.overline, classes.text)}>{format(new Date(date), "PP")}</p>
                <p className={clsx(typo.h4, classes.text)}>{title}</p>
                <p className={clsx(typo.body2, classes.text)}>{body}</p>
                {url && (
                    <ButtonLink href={url} style={{ backgroundColor: Colors.purple, color: Colors.white, width: 160 }}>
                        DISCOVER MORE
                    </ButtonLink>
                )}
                {pdf && (
                    <ButtonLink
                        href={`${NEWS_PATH}${pdf}`}
                        style={{ backgroundColor: Colors.purple, color: Colors.white, width: 160 }}
                    >
                        DISCOVER MORE
                    </ButtonLink>
                )}
            </div>
        </article>
    )
}

export default NewsItem
