import React, { FC, useState, useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import CookiesSnackbar from "../components/CookiesSnackbar"
import NewsItem from "../components/NewsItem"
import { useTypography } from "../styles/Typography"
import { INews } from "../types/types"
import { Api } from "../data/Api"
import {makeStyles} from "@material-ui/styles";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    body: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 48,
        padding: 24
    },
    bodyContent: {
        width: "100%",
        maxWidth: 1080
    },
    container: {
        width: "100%",
        height: "100%",
        display: "grid",
        justifyContent: "center"
    }
})

const NewsPage: FC = () => {
    const classes = useStyles()
    const typo = useTypography()
    const [newsData, setNewsData] = useState<INews[] | undefined>(undefined)

    useEffect(() => {
        const getData = async () => {
            const api = new Api()
            const items = await api.getNews()

            if (!items?.data) {
                return
            }

            setNewsData(items.data)
        }
        getData()
    }, [])

    return (
        <Layout>
            <SEO title="News" />
            <DiscoverMoreBanner/>
            <section className={classes.body}>
                <div className={classes.bodyContent}>
                    <h2 className={typo.h2} style={{ marginBottom: 32 }}>
                        News
                    </h2>
                    <div className={classes.container}>
                        {newsData?.map((value, index) => (
                            <NewsItem
                                date={value.date}
                                img={value.img}
                                title={value.title}
                                body={value.body}
                                url={value.url ?? ""}
                                pdf={value.pdf}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default NewsPage
